import React from 'react';
import { ShoppingCart, Menu } from 'lucide-react';

const ParaboxLanding = () => {
  const navItems = ['Boxes', 'Deals', 'FAQ', 'Contact us', 'Cart'];

  const contentSections = [
    {
      id: 1,
      title: "The Drawing Room Mystery",
      description: "Step into the haunted drawing room where the mysterious disappearance first occurred. Every object holds a clue, every shadow conceals a secret. Will you be the one to unravel the truth behind the Blackwood curse?",
      buttonText: "Explore the Room",
      imageUrl: "/images/landing/pic1.png",
      altText: "Victorian Drawing Room"
    },
    {
      id: 2,
      title: "The Forbidden Library",
      description: "Ancient tomes and cryptic manuscripts await in the manor's vast library. Each page turned could reveal the next piece of the puzzle. Dare you venture into this repository of dark knowledge?",
      buttonText: "Enter the Library",
      imageUrl: "/images/landing/pic2.png",
      altText: "Mysterious Library"
    },
    {
      id: 3,
      title: "The Séance Chamber",
      description: "In this dimly lit chamber, the veil between worlds grows thin. Join our exclusive midnight séance events where the spirits of Blackwood Manor may choose to reveal their secrets.",
      buttonText: "Reserve Your Seat",
      imageUrl: "/images/landing/pic3.png",
      altText: "Séance Room"
    },
    {
      id: 4,
      title: "Hidden Passages",
      description: "Behind every wall lies a potential pathway to the truth. Navigate the manor's secret network of corridors and chambers, each holding pieces of the Blackwood family's dark history.",
      buttonText: "Discover Secrets",
      imageUrl: "/images/landing/pic4.png",
      altText: "Secret Passage"
    }
  ];

  return (
    <div className="min-h-screen font-serif">
      {/* Navigation */}
      <nav className="fixed w-full z-50 bg-black/90 border-b border-purple-900/30">
        <div className="max-w-6xl mx-auto px-4">
          <div className="flex items-center justify-between h-16">
            <div className="hidden md:flex space-x-1">
              {navItems.map((item, index) => (
                <React.Fragment key={item}>
                  <button className="px-4 py-2 hover:text-orange-300 transition-colors">
                    {item === 'Cart' ? (
                      <div className="flex items-center gap-2">
                        <ShoppingCart className="h-5 w-5" />
                        {item}
                      </div>
                    ) : (
                      item
                    )}
                  </button>
                  {index < navItems.length - 1 && (
                    <span className="text-purple-500/50 self-center">|</span>
                  )}
                </React.Fragment>
              ))}
            </div>

            <button className="md:hidden p-2">
              <Menu className="h-6 w-6" />
            </button>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="relative h-screen-1/4 w-full overflow-hidden">
        <div
          className="absolute inset-0 bg-center"
          style={{
            backgroundImage: "url('/images/landing/pic1.png')",
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black" />
      </div>

      {/* Content Sections */}
      <div className="max-w-6xl mx-auto px-4 py-16 space-y-24">
        {contentSections.map((section, index) => (
          <div
            key={section.id}
            className={`flex flex-col ${
              index % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
            } gap-8 items-center`}
          >
            <div className="md:w-[60%] h-[400px] overflow-hidden rounded-sm shadow-2xl bg-purple-900/10">
              <div className="w-full h-full relative">
                <img
                  src={section.imageUrl}
                  alt={section.altText}
                  className="absolute inset-0 w-full h-full object-contain hover:scale-105 transition-transform duration-500"
                />
              </div>
            </div>
            <div className="md:w-[40%] space-y-4">
              <h2 className="parabox-heading">{section.title}</h2>
              <p className="parabox-text">{section.description}</p>
              <button className="parabox-button">
                {section.buttonText}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ParaboxLanding;