import React from 'react';

const FingerprintKeyPage = () => {
  return (
    <div className="min-h-screen bg-black flex flex-col items-center justify-center font-serif">
      <h1 className="text-3xl text-orange-300 mb-8">Fingerprints Key</h1>
      <div className="w-full max-w-2xl px-4">
        <img
          src="/images/fingerprintkeyweb.webp"
          alt="Fingerprint Key"
          className="w-full h-auto"
        />
      </div>
    </div>
  );
};

export default FingerprintKeyPage;