import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import ParaboxLanding from './components/ParaboxLanding'
import FingerprintKeyPage from './components/FingerprintKeyPage'

function App() {
  return (
    <div className="min-h-screen bg-black">
      <Router>
        <Routes>
          <Route path="/" element={<ParaboxLanding />} />
          <Route path="/fingerprint-key" element={<FingerprintKeyPage />} />
        </Routes>
      </Router>
    </div>
  )
}

export default App